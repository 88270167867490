<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <h1>个人信息</h1>
      </el-header>
      <el-main class="scrollable-main">
        <el-form label-width="80px" size="large">
          <el-upload
              class="avatar-uploader"
              action="http://39.106.92.106:9090/files/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
          >
            <img v-if="form.avatarUrl" :src="form.avatarUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

          <el-form-item label="用户名">
            <el-input v-model="form.username" disabled autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="角色">
            <el-input :value="roles.find(v => v.flag === form.role) ? roles.find(v => v.flag === form.role).name : ''" disabled autocomplete="off">
            </el-input>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input v-model="form.nickname" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="form.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="部门">
            <el-select clearable v-model="form.partId" placeholder="请选择部门" style="width: 100%">
              <el-option v-for="item in partments" :key="item.name" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车间">
            <el-select clearable v-model="form.lineId" placeholder="请选择车间" style="width: 100%">
              <el-option v-for="item in filteredLines" :key="item.name" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班次" v-if="user.role === 'ROLE_MAINTAINER'">
            <el-input v-model="form.workTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="小组" v-if="user.role === 'ROLE_MAINTAINER'">
            <el-input v-model="form.groupId" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain @click="clearInfo(form.id)">清空部门车间信息</el-button>
            <el-button type="primary" @click="save" style="margin-top: 10px">保存</el-button>
            <el-button type="primary" @click="logout" style="margin-top: 10px">退出</el-button>
          </el-form-item>
        </el-form>
      </el-main>
      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "MPerson",
  data() {
    return {
      form: {},
      roles: [],
      partments: [],
      lines: [],
      part:[],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load();
    this.getUser().then(res => {
      this.form = res
    })
  },
  computed: {
    filteredLines() {
      // 根据选择的部门过滤车间
      return this.lines.filter(line => line.partId === this.form.partId);
    },
  },
  methods: {
    async getUser() {
      return (await this.request.get("/user/username/" + this.user.username)).data
    },
    load() {
      this.request.get("/role").then(res => {
        this.roles = res.data;
      });
      this.request.get("/partment").then(res => {
        this.partments = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data;
      });
    },
    save() {
      this.request.put("/user/update",this.form).then(res => {
          if (res.code === '200') {  // 表示成功保存
            this.$message.success('保存成功')
            this.$emit("refreshUser");
            // 更新浏览器存储的用户信息
            this.getUser().then(res => {
              res.token = JSON.parse(localStorage.getItem("user")).token
              localStorage.setItem("user", JSON.stringify(res))
            })
          } else {
            this.$message.error(res.msg)  // 弹出错误的信息
          }
        })
    },
    handleAvatarSuccess(res) {
      this.form.avatarUrl = res;
    },
    clearInfo(userId){
      this.request.post("/user/clearInfo/" + userId).then(res => {
        if (res.code === '200') {
          this.$message.success("清空部门车间信息成功")
          this.getUser().then(res => {
            this.form = res
          })
        } else {
          this.$message.error("清空部门车间信息失败")
        }
      })
    },
    logout() {
      this.$store.commit("logout")
      this.$message.success("退出成功")
    },
  }
}
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}

.avatar-uploader {
  text-align: center;
  padding-bottom: 10px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 138px;
  height: 138px;
  line-height: 138px;
  text-align: center;
}

.avatar {
  width: 138px;
  height: 138px;
  display: block;
}
</style>
